import { FC, memo, useEffect, useMemo, useState } from 'react'
import { Box, Container, Grid, Typography, Zoom, styled } from '@mui/material'
import { array, boolean, nonEmptyArray, option } from 'fp-ts'
import { constVoid, pipe, tuple } from 'fp-ts/function'
import { Route } from 'common/Route'
import { Size } from 'common/Size'
import { TulipGroup } from 'common/TulipGroup'
import { ResponsiveImage } from 'common/types'
import * as css from 'lib/CSS'
import { metaData } from 'lib/MetaData'
import { Product, Store } from 'lib/Store'
import * as SA from 'lib/SelectArray'
import { ImgData } from 'lib/types'
import { mkShouldForwardProps } from 'lib/utils'
import { CallUsFab, FullscreenSlider, Header, Link, Main, Meta, Paragraph, ProductCard, Section, SectionHeader, Slider, Title } from 'components'

type Props = {
  product: Product
}

const getProductAttrs = (product: Product): Array<[string, string]> =>
  pipe(
    array.compact([
      option.some(tuple('Класс', TulipGroup.display(product.group))),
      pipe(option.fromNullable(product.attrs.color),        option.map(a => tuple('Окрас', a))),
      pipe(option.fromNullable(product.attrs.flowerHeight), option.map(a => tuple('Высота растения', Size.display(a)))),
      pipe(option.fromNullable(product.attrs.budHeight),    option.map(a => tuple('Высота бокала', Size.display(a)))),
      pipe(option.fromNullable(product.attrs.budDiameter),  option.map(a => tuple('Диаметр бокала', Size.display(a)))),
    ]),
  )

const PrimaryTitle = styled(Title)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4),
  },
}))

const SecondaryTitle = styled(Title)(({ theme }) => ({
  margin: theme.spacing(2, 0, 1.5),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(3),
  },
}))

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: theme.spacing(3.5),
  color: theme.palette.text.secondary,
  fontSize: css.rem(15/16),
}))

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5, 0),
}))

const DesktopSliderGrid = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const MobileSliderGrid = styled(Grid)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const AttributesGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  [theme.breakpoints.only('sm')]: {
    paddingLeft: theme.spacing(3),
  },
}))

const ParagraphBlock = styled(Paragraph)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const ProductItemGrid = styled(Grid, {
  shouldForwardProp: mkShouldForwardProps(['hiddenOnMd']),
})<{ hiddenOnMd?: boolean }>(({ theme, hiddenOnMd }) => ({
  [theme.breakpoints.only('md')]: {
    display: hiddenOnMd ? 'none' : undefined,
  },
}))

const benefits = [
  '10 лет на рынке позволили нам отобрать самые стойкие и востребованные сорта, выращенные исключительно из голландской луковицы',
  'Тюльпан срезается только к 1 марта, чтобы наши клиенты получали самые свежие цветы',
  'После срезки тюльпан попадает в холодильную камеру где поддерживается постоянная температура',
  'Цветок проходит предпродажную сортировку и покупатель получает тюльпан только высшего качества',
]

export const ProductContainer: FC<Props> = memo(({ product }) => {
  const attrs = getProductAttrs(product)
  const [fullscreenSliderOpen, setFullscreenSliderOpen] = useState(false)
  const [sliderData, setSliderData] = useState(SA.fromNonEmptyArray(product.images))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const similar = useMemo(Store.getSimilar(product.id, 8), [product])

  useEffect(() => pipe(
    ResponsiveImage.eq.equals(nonEmptyArray.head(product.images), sliderData.value),
    boolean.fold(() => setSliderData(SA.fromNonEmptyArray(product.images)), constVoid)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [product])

  return (
    <>
      <Meta metaData={metaData.productPage(product)} />

      <Header />

      <FullscreenSlider
        open={fullscreenSliderOpen}
        data={sliderData}
        onCurrentImgChange={img => setSliderData(SA.select(ImgData.eq)(img, sliderData))}
        onBefore={() => setSliderData(SA.selectBefore(sliderData))}
        onNext={() => setSliderData(SA.selectNext(sliderData))}
        onClose={() => setFullscreenSliderOpen(false)}
      />

      <Main>
        <Zoom in={!fullscreenSliderOpen} appear={false} unmountOnExit>
          <CallUsFab />
        </Zoom>

        <Section>
          <Container maxWidth="lg" fixed>
            <Grid container spacing={4}>
              <DesktopSliderGrid item xs={5}>
                <Slider
                  data={sliderData}
                  onCurrentImgChange={img => setSliderData(SA.select(ImgData.eq)(img, sliderData))}
                  onFullscreen={() => setFullscreenSliderOpen(true)}
                />
              </DesktopSliderGrid>

              <Grid component="article" item xs={12} md={7} container alignContent="flex-start">
                <Grid item xs={12}>
                  <PrimaryTitle component="h1" fontSize={{ xs: 1.375, sm: 1.5 }}>
                    {product.name.full}
                  </PrimaryTitle>
                </Grid>

                <MobileSliderGrid item xs={12} sm={8}>
                  <Slider
                    data={sliderData}
                    onCurrentImgChange={img => setSliderData(SA.select(ImgData.eq)(img, sliderData))}
                    onFullscreen={() => setFullscreenSliderOpen(true)}
                  />
                </MobileSliderGrid>

                <AttributesGrid item xs={12} sm={4} md={12}>
                  <Grid container spacing={2}>
                    {attrs.map(([ label, text ], i) => (
                      <Grid key={i} item xs={6} sm={12} md={4} zeroMinWidth>
                        <Box mb={0.5}>
                          <Title component="h3" tone="dark" fontSize={14/16} fontWeight={600} noWrap>
                            {label}
                          </Title>
                        </Box>
                        <Paragraph noWrap>
                          {text}
                        </Paragraph>
                      </Grid>
                    ))}
                  </Grid>
                </AttributesGrid>

                <Grid item xs={12}>
                  {product.reserved ? (
                    <>
                      <Typography variant="body1" color="textSecondary">
                        Доступность:&nbsp;
                        <Typography fontWeight={600} component="span" variant="body1" color="textPrimary">
                          забронированы
                        </Typography>
                        .
                      </Typography>
                      <Link to={Route.catalog.path} variant="body1" display="inline">
                        Смотреть каталог
                      </Link>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" color="textSecondary">
                        Доступность:&nbsp;
                        <Typography fontWeight={600} component="span" variant="body1" color="textPrimary">
                          есть в наличии
                        </Typography>
                        .
                      </Typography>
                      <Link to={Route.contact.path} variant="body1" display="inline">
                        Как оформить заказ?
                      </Link>
                    </>
                  )}

                  {product.description.map((text, i) => (
                    <ParagraphBlock key={i}>
                      {text}
                    </ParagraphBlock>
                  ))}

                  <SecondaryTitle component="h3" fontSize={20/16}>
                    Почему стоит заказать именно у нас
                  </SecondaryTitle>

                  <List>
                    {benefits.map((text, i) => <ListItem key={i}>{text}</ListItem>)}
                  </List>

                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Section>

        {pipe(
          similar,
          option.map(similar => (
            <Section dense dark>
              <Container maxWidth="lg" fixed>
                <SectionHeader dense>
                  <Title component="h3" fontSize={18/16}>
                    Вам также может понравиться
                  </Title>
                </SectionHeader>
                <Grid container spacing={{ xs: 2, sm: 3 }}>
                  {similar.map((product, i) => (
                    <ProductItemGrid
                      key={product.id}
                      item
                      xs={6}
                      md={4}
                      lg={3}
                      hiddenOnMd={i === 0}
                    >
                      <ProductCard product={product} />
                    </ProductItemGrid>
                  ))}
                </Grid>
              </Container>
            </Section>
          )),
          option.toNullable,
        )}
      </Main>
    </>
  )
})
