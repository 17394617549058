import * as D from 'io-ts/Decoder'

const TulipGroupDecoder = D.literal(
  'darwin-hybrid',
  'double-early',
  'double-fringed',
  'double-late',
  'fringed',
  'triumph'
)

export type TulipGroup = D.TypeOf<typeof TulipGroupDecoder>

const display = (group: TulipGroup): string => {
  switch (group) {
    case 'darwin-hybrid':
      return 'Дарвинов гибрид'

    case 'double-early':
      return 'Махровые ранние / пионовидные'

    case 'double-fringed':
      return 'Махрово-бахромчатые'

    case 'double-late':
      return 'Махровые поздние'

    case 'fringed':
      return 'Бахромчатые'

    case 'triumph':
      return 'Триумф'
  }
}

export const TulipGroup = {
  decoder: TulipGroupDecoder,
  display,
}
