import { FC, memo, useState } from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { AppBar, Box, Container, Divider, Grid, IconButton, Link as MaterialLink, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Typography, useTheme, styled } from '@mui/material'
import { Route, RoutePath } from 'common/Route'
import { Contact } from 'lib/Contact'
import * as css from 'lib/CSS'
import { iOS } from 'const'
import { Link } from './Link'
import { Title } from './Title'

import CallIcon from '@mui/icons-material/Call'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import HomeIcon from '@mui/icons-material/Home'
import LocalFlorist from '@mui/icons-material/LocalFlorist'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import SvgIcon from '@mui/material/SvgIcon'
import { infoJson } from 'lib/JsonData'

const barBackground = css.rgba(255, 255, 255, 0.75)
const barShadow = [
  `${css.px(0, 3, 1, -2)} ${css.rgba(0, 0, 0, 0.16)}`,
  `${css.px(0, 2, 2, 0)}  ${css.rgba(0, 0, 0, 0.11)}`,
  `${css.px(0, 1, 5, 0)}  ${css.rgba(0, 0, 0, 0.10)}`,
].join(',')

const DesktopBarContainer = styled('div')(({ theme }) => ({
  zIndex: 1,
  display: 'none',
  backgroundColor: barBackground,
  boxShadow: barShadow,
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}))

const DesktopBar = styled('header')(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  borderColor: css.rgba(221, 221, 221, 0.54),
  borderWidth: css.px(0, 0, 1, 0),
  borderStyle: 'solid',
}))

const MobileBarContainer = styled('div')(({ theme }) => ({
  display: 'block',
  height: theme.spacing(8.25),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const MobileBar = styled(AppBar)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: barBackground,
  boxShadow: barShadow,
}))

const SListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(5),
}))

type NavRoute = {
  label: string
  mobileIcon: typeof SvgIcon
  path: RoutePath
  exact?: boolean
}

const nav: Array<NavRoute> = [
  {
    label: 'Главная',
    mobileIcon: HomeIcon,
    path: Route.home.path,
    exact: true,
  },
  {
    label: `Каталог ${infoJson.displayYear}`,
    mobileIcon: LocalFlorist,
    path: Route.catalog.path,
  },
  {
    label: 'Фотогалерея',
    mobileIcon: PhotoLibraryIcon,
    path: Route.gallery.path,
  },
  {
    label: 'Контакт',
    mobileIcon: PersonIcon,
    path: Route.contact.path,
  },
]

const SRouterLink = styled(RouterNavLink)(({ theme }) => ({
  color: css.gray.dark,
  textDecoration: 'none',
  transition: theme.transitions.create('color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const NavLink: FC<{ to: RoutePath, exact?: boolean }> =
  props => {
    const theme = useTheme()

    return (
      <SRouterLink
        activeStyle={{ color: theme.palette.primary.main }}
        to={props.to}
        exact={props.exact}
      >
        <Title
          component="span"
          tone="inherit"
          fontSize={13/16}
          fontWeight={700}
          letterSpacing={0.04}
          uppercase
          noWrap
        >
          {props.children}
        </Title>
      </SRouterLink>
    )
  }

export const Header: FC = memo(() => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const openDrawer = () => setDrawerOpen(true)
  const closeDrawer = () => setDrawerOpen(false)

  return (
    <>
      <DesktopBarContainer>
        <DesktopBar>
          <Container maxWidth="lg" fixed>
            <Grid container justifyContent="space-between" wrap="nowrap">
              <Grid item zeroMinWidth>
                <Typography color="textSecondary" noWrap>
                  Тюльпаны оптом с доставкой
                </Typography>
              </Grid>

              <Grid item>
                <Typography color="primary" display="inline" noWrap>
                  <b>{Contact.phone.label}:&nbsp;</b>

                  <MaterialLink
                    color="textSecondary"
                    variant="body1"
                    href={Contact.phone.href}
                    underline="hover"
                  >
                    {Contact.phone.display}
                  </MaterialLink>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </DesktopBar>

        <Box component="nav" py={3}>
          <Container maxWidth="lg" fixed>
            <Grid container wrap="nowrap">
              <Grid item sx={{ display: 'flex', alignItems: 'center', pr: 4 }}>
                <Link to={Route.home.path} underline="none">
                  <Title
                    component="span"
                    tone="light"
                    fontSize={30/16}
                    fontWeight={800}
                  >
                    LikaCvetok
                  </Title>
                </Link>
              </Grid>

              <Grid item container alignItems="center" justifyContent="flex-end" spacing={3}>
              {nav.map(({ label, path, exact }) => (
                <Grid key={label} item>
                  <NavLink to={path} exact={exact}>
                    {label}
                  </NavLink>
                </Grid>
              ))}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </DesktopBarContainer>

      <MobileBarContainer>
        <MobileBar position="fixed">
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <IconButton onClick={openDrawer}>
                <MenuIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <Link to={Route.home.path} underline="none">
                <Title
                  component="span"
                  tone="light"
                  fontSize={24/16}
                  fontWeight={800}
                >
                  LikaCvetok
                </Title>
              </Link>
            </Grid>
          </Grid>
        </MobileBar>
      </MobileBarContainer>

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        disableDiscovery={iOS}
        onOpen={openDrawer}
        onClose={closeDrawer}
      >
        <Box py={1.5} pl={0.5} pr={3}>
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <IconButton onClick={closeDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <Title
                component="span"
                tone="light"
                fontSize={24/16}
                fontWeight={800}
              >
                LikaCvetok
              </Title>
            </Grid>
          </Grid>
        </Box>

        <Divider light />

        <List component="nav">
          {nav.map(({ label, path, mobileIcon: Icon }) => (
            <Link key={label} to={path} color="inherit" underline="none" onClick={closeDrawer}>
              <ListItem button>
                <SListItemIcon>
                  <Icon fontSize="small" />
                </SListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            </Link>
          ))}
        </List>

        <Divider light />

        <List>
          <ListItem
            component={MaterialLink}
            href={Contact.phone.href}
            color="inherit"
            underline="none"
            button
          >
            <SListItemIcon>
              <CallIcon fontSize="small" />
            </SListItemIcon>

            <ListItemText primary={Contact.phone.display} />
          </ListItem>
        </List>
      </SwipeableDrawer>
    </>
  )
})
