import { FC, memo } from 'react'
import { Box, Button, Container, Grid, styled } from '@mui/material'
import * as css from 'lib/CSS'
import { metaData } from 'lib/MetaData'
import { Route } from 'common/Route'
import { Banner, Benefit, CallUsFab, Header, LazyImage, LinkBase, Main, Meta, Paragraph, Section, Title } from 'components'
import LaunchIcon from '@mui/icons-material/Launch'
import { infoJson } from 'lib/JsonData'
import { toS3BucketUrl } from 'const'

const SLazyImage = styled(LazyImage)(({ theme }) => ({
  padding: theme.spacing(0.25),
  width: css.percentage(1/3),
  objectFit: 'cover',
}))

export const HomeContainer: FC = memo(() => {
  const images: Array<{ src: string, alt: string }> = [
    {
      src:'/assets/home-img-1.b6137680f658fe3ade378f0cdecddf483dbce01d.jpg',
      alt: 'Белые тюльпаны',
    },
    {
      src: '/assets/home-img-2.57864f5be5c5af53ee27005e75134ab89574c81c.jpg',
      alt: 'Сиреневые тюльпаны'
    },
    {
      src: '/assets/home-img-3.c3f876310376cd4cd95faa3bdbb50d2b81f1b278.jpg',
      alt: 'Жёльтые тюльпаны'
    },
  ]

  return (
    <>
      <Meta metaData={metaData.homePage} />

      <Banner
        title="Тюльпаны к 8 марта от производителя"
        subtitle="ОПТ и мелкий ОПТ"
        imageSrc="/assets/home-banner.jpg"
      >
        <Header />
      </Banner>

      <Main>
        <CallUsFab />

        <Section>
          <Container>
            <Title component="h3" align="center" fontSize={{ xs: 1.375, sm: 1.5 }} gutterBottom uppercase>
              Новый ассортимент на {infoJson.displayYear}
            </Title>
            <Paragraph align="center">
              Лучший тюльпан в Гродно по словам наших клиентов!
            </Paragraph>

            <Box sx={{ p: -1, mt: 5, display: 'flex', height: { xs: 175, sm: 250, md: 300 } }}>
              {images.map(image => (
                <SLazyImage
                  key={image.src}
                  src={image.src}
                  alt={image.alt}
                />
              ))}
            </Box>

            <Box mt={5} display="flex" justifyContent="center">
              <Button
                component={LinkBase}
                to={Route.catalog.path}
                color="primary"
                variant="contained"
                size="large"
                endIcon={<LaunchIcon />}
              >
                Смотреть каталог
              </Button>
            </Box>
          </Container>
        </Section>

        <Section dark>
          <Container maxWidth="lg" fixed>
            <Grid container spacing={4}>
              <Grid item sm={12} md={4}>
                <Benefit
                  title="Отборная луковица"
                  imageSrc="/assets/icons/tulip.svg"
                  imageAlt="Иконка тюльпана"
                >
                  10 лет на рынке позволили нам отобрать самые стойкие и востребованные сорта,
                  выращенные исключительно из голландской луковицы
                </Benefit>
              </Grid>

              <Grid item sm={12} md={4}>
                <Benefit
                  title="Доставка в Минск"
                  imageSrc="/assets/icons/truck.svg"
                  imageAlt="Иконка грузовика"
                >
                  Вам не придётся далеко ехать! Мы доставим Ваш заказ в столицу
                </Benefit>
              </Grid>

              <Grid item sm={12} md={4}>
                <Benefit
                  title="Забота о клиенте"
                  imageSrc="/assets/icons/heart.svg"
                  imageAlt="Иконка сердечка"
                >
                  У нас Вы можете бесплатно получить индивидуальную консультацию и рекомендации с
                  выбором сортов для любого бюджета
                </Benefit>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </Main>
    </>
  )
})
