export const origin = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000'
  : 'https://likacvetok.by'

export const toOriginUrl =
  (a: string) => origin + a

export const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

export const s3Bucket = 'https://likacvetok-bucket.s3.eu-central-1.amazonaws.com'

// TODO provide this as runtime env prefix
export const toS3BucketUrl =
  (a: string) => s3Bucket + a
